import React, { useState, useEffect  } from 'react';
import { Link, useLocation  } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  function ScrollToTopOnNavigate() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }

  

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
        <ScrollToTopOnNavigate />
          <Link to='/' className='navbar-logo pb-1' onClick={closeMobileMenu}>
            SalveSoft
            <img src={require('../Assets/SSS-LOGO.jpeg')} alt='logo' style={{height:40, paddingLeft:10}}/>
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/about'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                About
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/services'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Services
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/contact'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Contact
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/career'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Career
              </Link>
            </li>
          </ul>
         
        </div>
      </nav>
    </>
  );
}

export default Navbar;
