import CareerCard from "../../CareerCard";

function SrDataEngineer() {
    return (
      <div className="container pt-5">
        <h1>Sr. Data Engineer (AWS)</h1>
        <div className="row">
          <div className="col">
            <h4>Job Description</h4>
            <p>As an AWS Sr. Data Engineer, you will solve some of the most impactful business problems for our clients using a variety of AWS services. You will collaborate with business partners and domain experts to design and develop innovative solutions with the models to achieve predefined outcomes.</p>
            <h1>Responsibilities</h1>
            <ul>
              <li>Collect and ingest data from various sources into AWS, such as databases, streaming platforms, logs, APIs, and external data providers.</li>
              <li>Design and manage data storage solutions on AWS, including data lakes, data warehouses, and NoSQL databases like Amazon S3, Amazon Redshift, Amazon DynamoDB, and others.</li>
              <li>Implement ETL (Extract, Transform, Load) processes to clean, transform, and enrich raw data into a format suitable for analysis and reporting.</li>
              <li>Implement ETL (Extract, Transform, Load) processes to clean, transform, and enrich raw data into a format suitable for analysis and reporting.</li>
              <li> Ensure data security and compliance with AWS security best practices by configuring access controls, encryption, and monitoring.</li>
              <li>Implement data quality checks, data profiling, and data governance practices to maintain data accuracy and consistency.</li>
              <li>Monitor and optimize data pipelines and storage solutions for performance, scalability, and cost-effectiveness.</li>
              <li> Build real-time data processing solutions using AWS services like Amazon Kinesis, AWS Lambda, or Apache Kafka for streaming data.</li>
            </ul>
            <h4>Requirements</h4>
            <ul>
                <li>BE/B-Tech/MTech</li>
                <li>5+ years of work experience with ETL, Data Modelling, and/or Data Architecture.</li>
                <li>AWS data factory, Logic apps, API management, MySQL</li>
                <li>Experience in Database engineering and refactoring, with ETL using AWS data factory.</li>
                <li>Experience with Data warehouse development and maintenance with AWS Synapse Database.</li>
                <li>Proficiency in SQL and experience with different database systems, including relational databases (e.g., PostgreSQL, MySQL) and NoSQL databases (e.g., MongoDB, Cassandra).</li>
                <li>Extensive Pipeline development using AWS Data Factory and Databricks including exposure to Cloud functions</li>
                <li>Translate business requirements to technical specs and execute</li>
                <li>Good communication skills and soft skills for team/stakeholder management</li>
            </ul>
          </div>
          <div className="col pt-5 pb-5" style={{minWidth:'350px'}}>
            <CareerCard
            title="Sr. Data Engineer (AWS)"
            location="Nagpur, IN"
            description="As an AWS Sr. Data Engineer, you will solve some of the most impactful business problems for our clients using a variety of AWS services."
            learnMoreButton={false}
            contactEmail=" hr@salvesoft.com"
            showMailButton={true}
            />
          </div>
        </div>
      </div>
    );
  }
  
  export default SrDataEngineer;