const CareerCard = ({ title, location, description, linkTo, contactEmail, showMailButton, learnMoreButton }) => {


  const openEmailClient = () => {
    const emailSubject = `Regarding ${title} Job`;
    const emailBody = ` `;
    const mailtoLink = `mailto:${contactEmail}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
    window.location.href = mailtoLink;
  };


  return (
    <div className="card p-3">
      <h2>{title}</h2>
      <p className="m-0">{location}</p>
      <p style={{color:"grey"}}>{description}</p>
      <div className="d-flex">
        <a href={linkTo} rel="noopener noreferrer">
          {learnMoreButton && (
              <button className='career-btn'>Learn More</button>
          )}
          {/* <button className='career-btn'>Learn More</button> */}
          {showMailButton && (
            <button className='career-btn' onClick={openEmailClient}>Apply now</button>
          )}
        </a>
      </div>
    </div>
  );
};

export default CareerCard;