import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { ImLocation2,ImPhone,ImMail3 } from 'react-icons/im';

function Footer() {
  return (
    <div className='footer-container'> 

          <div className='p-5' style={{color:"white"}}>
            <h4 >Contact Info</h4>
            <div className='p-4'>
              <div class="row">
                <div class="col-md-auto">
                <div className='d-flex gap-3' style={{color:"white"}}>
                    <div>
                      <ImLocation2 fontSize="1.5em"/>
                    </div> 
                    <div>
                      INDIA
                      <p>3, Nehru Nagar, Nagpur, MH 440015</p>
                    </div> 
                  </div>

                  <div className='d-flex gap-3' style={{color:"white"}}>
                    <div>
                      <ImPhone fontSize="1.5em"/>
                    </div> 
                    <div>
                      PHONE
                      <p>+91 7972845317</p>
                    </div> 
                  </div>

                  <div className='d-flex gap-3' style={{color:"white"}}>
                    <div>
                      <ImMail3 fontSize="1.5em"/>
                    </div> 
                    <div>
                      EMAIL
                      <p>hr@salvesoft.com</p>
                    </div> 
                  </div>

                  <div className='d-flex gap-3' style={{color:"white"}}>
                    <div>
                      <ImMail3 fontSize="1.5em"/>
                    </div> 
                    <div>
                      SALES
                      <p>sales@salventure.tech</p>
                    </div> 
                  </div>
                </div>
                <div class="col-md-auto">
                  {/* <p className='m-0'>Another Stuff</p>
                  <p className='m-0'>Lorem</p> */}
                </div>
              </div>
            </div>
          </div>
  



      <section className='social-media'>
        <div className='social-media-wrap pb-3'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              SalveSoft
              <img src={require('../Assets/SSS-LOGO.jpeg')} alt='logo' style={{height:40, paddingLeft:10}}/>
            </Link>
          </div>
          <div>
          <small class='website-rights'>SalveSoft © 2023</small>
          </div>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='https://www.facebook.com/salvesoft'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            {/* <Link
              class='social-icon-link instagram'
              to='#'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='#'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link> */}
            <Link
              class='social-icon-link twitter'
              to='#'
              // target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link linkedin'
              to='https://www.linkedin.com/company/salvesoft/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
