import CareerCard from "../../CareerCard";

function SalesManager() {
    return (
      <div className="container pt-5">
        <h1>Sales Manager </h1>
        <div className="row">
          <div className="col">
            <h4>Job Description</h4>
            <p>We are looking for a talented and experienced Full Stack Developer to join our team. AsA Sales Manager is a professional who is responsible for the success of their company’s sales department. They set goals for their team and produce monthly forecasting reports to see how well they’re doing in achieving those targets and budgets based on what’s happening with revenue streams so that everything runs smoothly from month to month.</p>
            <h1>Responsibilities</h1>
            <ul>
              <li>Achieve growth and hit sales targets by successfully managing the sales team</li>
              <li>Design and implement a strategic business plan that expands company’s customer base and ensure it’s strong presence</li>
              <li>Own recruiting, objectives setting, coaching and performance monitoring of sales representatives</li>
              <li>Build and promote strong, long-lasting customer relationships by partnering with them and understanding their needs</li>
              <li>Present sales, revenue and expenses reports and realistic forecasts to the management team</li>
              <li>Identify emerging markets and market shifts while being fully aware of new products and competition status</li>
            </ul>
            <h4>Requirements</h4>
            <ul>
              <li>BS/MS degree in business administration or a related field</li>
              <li>Successful previous experience as a sales representative or sales manager, consistently meeting or exceeding targets</li>
              <li>Committed to continuous education through workshops, seminars and conferences</li>
              <li>Demonstrated ability to communicate, present and influence credibly and effectively at all levels of the organization</li>
              <li>Proven ability to drive the sales process from plan to close</li>
              <li>Strong business sense and industry expertise</li>
            </ul>
          </div>
          <div className="col pt-5 pb-5" style={{minWidth:'350px'}}>
            <CareerCard
            title="Sales Manager"
            location="Nagpur, IN"
            description="Engages customers, promotes products or services, and completes sales transactions to achieve revenue and provide excellent customer service."
            learnMoreButton={false}
            contactEmail=" hr@salvesoft.com"
            showMailButton={true}
            />
          </div>
        </div>
      </div>
    );
  }
  
  export default SalesManager;