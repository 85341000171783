import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import { Helmet } from 'react-helmet-async';


function Home() {
  return (
    <>
    <Helmet prioritizeSeoTags>
      <title>SalveSoft</title>
      <meta
      name="description"
      content="SalveSoft established in 2014 is an ISO 9001:2015 certified Engineering outperformer"
      />
      <link rel="canonical" href="/" />
    </Helmet>
      <HeroSection />
      <Cards />
    </>
  );
}

export default Home;
