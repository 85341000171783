import React from 'react';
import '../../App.css';

export default function About() {
  return (
    <div>
      <div className='about'>
        <h1 className='p-5'>We’re a highly collaborative and supportive team, coming together on every project to ensure our clients get the very best result.</h1>
      </div>

      <div className='container pt-5'>
        <div className='row'>
        <div className='col-lg'>
          <h1 style={{textAlign:"start"}}>Why Love</h1>
          <h1 style={{textAlign:"start"}}>SalveSoft</h1>
          <p>With presence across continents and a dedicated team to cater to your requirements, we give you every reason to succeed in your venture.</p>
        </div>
        <div className='col-sm pt-5' style={{display:"grid", alignContent:"center", justifyContent:"center"}}>
          <p>9+ Years of Experience</p>
          <p>Quick Turnaround</p>
        </div>
        <div className='col-sm pt-5' style={{display:"grid", alignContent:"center", justifyContent:"center"}}>
          <p>9+ Years of Experience</p>
          <p>Quick Turnaround</p>
        </div>
        
        </div>
      </div>
      
      <div class="container pt-5 pb-5">
          <div class="row">
            <div class="col-sm">
              <div style={{textAlign:'center'}}>
               <img src={require('../../Assets/autoopti.jpeg')} alt='Autoopti' fluid/>
              </div>
            </div>
            <div class="col-sm">
              <div style={{textAlign:'center'}}>
                <h3 className='pt-3'>Quality Management</h3>
                <p>We are an ISO certified venture and actively practice quality management in order to ensure product quality, meet customer requirements, identify errors early in the development process and initiate their swift elimination. We use metrics and statistical methods to record and improve quality and to get better – day by day.</p>
              </div>
            </div>
          </div>
        </div>


      <div className='pt-5 pb-5'>
        <hr></hr>
        <h1>OUR CLIENTS</h1>
          <div className="image-gallery pt-5 pb-5">
            <img src={require('../../Assets/expert-heavy-equipment.webp')} alt='expert-heavy-equipment' style={{ width: '150px', height: '100px' }}/>
            <img src={require('../../Assets/Checklist-mom.webp')} alt='Checklist-mom' style={{ width: '150px', height: '100px' }}/>
            <img src={require('../../Assets/mera-shop.png')} alt='mera-shop' style={{ width: '150px', height: '150px' }}/>
            <img src={require('../../Assets/GroteLogo-Red-PMS_185-RGB.jpg')} alt='GroteLogo' style={{ width: '150px', height: '100px' }}/>
            <img src={require('../../Assets/super-tax-refunds.png')} alt='super-tax-refunds' style={{ width: '150px', height: '100px' }}/>
            <img src={require('../../Assets/ander-blake.png')} alt='ander-blake' style={{ width: '150px', height: '100px' }}/>
          </div>
          <hr></hr>
      </div>


    </div>   
    
  );
}
