import React from "react";
import CareerCard from "../CareerCard";

function Career() {
  return (
   <div className=" pb-5">
    <h1 className='career'>Kick start your career with us</h1>

    <div class="container">
    <h1 className="p-3 text-center">Open position</h1>
      <div class="row justify-content-md-center" style={{rowGap:"2rem"}}>
        <div class="col-md-6">
          <CareerCard
          title="Full Stack Developer" 
          location="Nagpur, IN"
          description="As a Full Stack Developer, you will be responsible for designing, developing, and maintaining our web applications."
          linkTo="/career/full-stack-developer"
          learnMoreButton={true}
           />
        </div>
        <div class="col-md-6">
          <CareerCard
          title="Flutter Developer" 
          location="Nagpur, IN"
          description="As a App Developer, you will be responsible for designing, developing, and maintaining our mobile applications."
          linkTo="/career/app-developer"
          learnMoreButton={true}
           />
        </div>
        <div class="col-md-6">
          <CareerCard
          title="Sr. Data Engineer (AWS)" 
          location="Nagpur, IN"
          description="As an AWS Sr. Data Engineer, you will solve some of the most impactful business problems for our clients using a variety of AWS services."
          linkTo="/career/sr-data-engineer"
          learnMoreButton={true}
           />
        </div>
        <div class="col-md-6">
          <CareerCard
            title="Tele Caller Executive"
            location="Nagpur, IN"
            description="Work experience as a Telecaller, TeleMarketer, or a similar role with 0-1 year experience. Exceptional oral and written communication skills."
            linkTo="/career/tele-caller-executive"
            learnMoreButton={true}
            />
        </div>
        <div class="col-md-6">
          <CareerCard
          title="HR Manager"
          location="Nagpur, IN"
          description="Managing personnel, recruitment, and employee relations to support organizational growth and ensure a positive workplace environment."
          linkTo="/career/hr-manager"
          learnMoreButton={true}
           />
        </div>
        <div class="col-md-6">
          <CareerCard
          title="Sales Manager"
          location="Nagpur, IN"
          description="Engages customers, promotes products or services, and completes sales transactions to achieve revenue and provide excellent customer service."
          linkTo="/career/sales-manager"
          learnMoreButton={true}

           />
        </div>
        <div class="col-md-6">
          <CareerCard
          title="Electrical Hardware Design Engineer"
          location="Nagpur, IN"
          description="Specializes in creating and developing electrical circuits and components for various applications, ensuring optimal performance and functionality."
          linkTo="/career/electrical-hardware-engineer"
          learnMoreButton={true}
           />
        </div>
      </div>
    </div> 

   </div>
  );
}

export default Career;