import CareerCard from "../../CareerCard";

function HrManager() {
    return (
      <div className="container pt-5">
        <h1>HR Manager </h1>
        <h4>Job Description</h4>
        <div className="row">
          <div className="col">
            <p>Human Resource Managers are professionals tasked with strategic planning for staffing, conducting interviews, and hiring personnel to foster a productive work atmosphere. They consult executives on decisions impacting all management levels, fostering sustainable transformation within their business or company.</p>
            <h1>Responsibilities</h1>
            <ul>
              <li>Develop and implement HR strategies and initiatives aligned with the overall business strategy</li>
              <li>Bridge management and employee relations by addressing demands, grievances or other issues</li>
              <li>Manage the recruitment and selection process</li>
              <li>Support current and future business needs through the development, engagement, motivation and preservation of human capital</li>
              <li>Develop and monitor overall HR strategies, systems, tactics and procedures across the organization</li>
              <li>Nurture a positive working environment</li>
              <li>Oversee and manage a performance appraisal system that drives high performance</li>
              <li>Report to management and provide decision support through HR metrics</li>
              <li>Ensure legal compliance throughout human resource management</li>
            </ul>
            <h4>Requirements</h4>
            <ul>
              <li>Proven working experience as HR Manager or other HR Executive</li>
              <li>People oriented and results driven</li>
              <li>Demonstrable experience with Human Resources metrics</li>
              <li>Knowledge of HR systems and databases</li>
              <li>Ability to architect strategy along with leadership skills</li>
              <li>Excellent active listening, negotiation and presentation skills</li>
              <li>Competence to build and effectively manage interpersonal relationships at all levels of the company</li>
              <li>In-depth knowledge of labor law and HR best practices</li>
              <li>Degree in Human Resources or related field</li>
            </ul>
          </div>
          <div className="col pt-5 pb-5" style={{minWidth:'350px'}}>
            <CareerCard
            title="HR Manager"
            location="Nagpur, IN"
            description="Managing personnel, recruitment, and employee relations to support organizational growth and ensure a positive workplace environment."
            learnMoreButton={false}
            contactEmail=" hr@salvesoft.com"
            showMailButton={true}
            />
          </div>
        </div>
      </div>
    );
  }
  
  export default HrManager;