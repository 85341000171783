import CareerCard from "../../CareerCard";

function FullStackDeveloper() {
    return (
      <div className="container pt-5">
        <h1>Full Stack Developer </h1>
        <div className="row">
          <div className="col">
            <h4>Job Description</h4>
            <p>We are looking for a talented and experienced Full Stack Developer to join our team. As a Full Stack Developer, you will be responsible for designing, developing, and maintaining our web applications. You will work closely with our cross-functional team to deliver high-quality, scalable, and efficient software solutions. If you are passionate about web development, have strong problem-solving skills, and thrive in a collaborative environment, we would like to meet you.</p>
            <h1>Responsibilities</h1>
            <ul>
                <li>Design and develop scalable and responsive web applications from concept to deployment.</li>
                <li>Collaborate with product managers, designers, and other stakeholders to gather and understand requirements.</li>
                <li>Write clean, efficient, and maintainable code using appropriate technologies and best practices.</li>
                <li>Build and maintain backend APIs and databases to support front-end functionality.</li>
                <li>Implement user authentication and authorization systems.</li>
                <li>Conduct thorough testing to identify and fix bugs and ensure application stability.</li>
                <li>Optimize application performance and ensure seamless user experience.</li>
                <li>Collaborate with the front-end development team to integrate user-facing elements with server-side logic.</li>
                <li>Stay updated with the latest trends and advancements in web development technologies and recommend improvements to enhance the application’s features and functionality.</li>
                <li>Participate in code reviews to maintain code quality and ensure adherence to coding standards.</li>
                <li>Provide technical guidance and support to junior developers as needed.</li>
            </ul>
            <h4>Requirements</h4>
            <ul>
                <li>Proven experience as a Full Stack Developer or similar role.</li>
                <li>Strong knowledge of web development technologies such as HTML, CSS, JavaScript, and frameworks like React, Angular, or Vue.js.</li>
                <li>Proficient in server-side languages such as Node.js, Python, Ruby, or PHP.</li>
                <li>Experience working with databases like MySQL, PostgreSQL, or MongoDB.</li>
                <li>Familiarity with version control systems such as Git.</li>
                <li>Strong understanding of web architecture and RESTful APIs.</li>
                <li>Excellent problem-solving and analytical skills.</li>
                <li>Ability to work in a fast-paced environment and manage multiple projects simultaneously.</li>
                <li>Good communication skills and the ability to effectively collaborate with cross-functional teams.</li>
                <li>Attention to detail and commitment to delivering high-quality work.</li>
                <li>A portfolio of previous web applications developed as a Full Stack Developer would be an advantage.</li>
            </ul>
          </div>
          <div className="col pt-5 pb-5" style={{minWidth:'350px'}}>
            <CareerCard
            title="Full Stack Developer"
            location="Nagpur, IN"
            description="As a Full Stack Developer, you will be responsible for designing, developing, and maintaining our web applications."
            learnMoreButton={false}
            contactEmail=" hr@salvesoft.com"
            showMailButton={true}
            />
          </div>
        </div>
      </div>
    );
  }
  
  export default FullStackDeveloper;