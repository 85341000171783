import React from 'react';
import '../../App.css';
// import { Helmet } from 'react-helmet-async';

export default function Services() {
  return (
    <>
    {/* <Helmet prioritizeSeoTags>
      <title>Services</title>
      <meta
      name="description"
      content="Explore our comprehensive services"
      />
      <link rel="canonical" href="/services" />
    </Helmet> */}
    <div>
      <div className='services'>
        <h1>Explore our comprehensive services</h1>
      </div>
        <div class='container pt-5 pb-5'>
          <div class="row">
            <div class="col p-5" style={{ display:"flex"}}>
              <img src={require('../../Assets/integration.jpeg')} alt='Integration' fluid/>
            </div>
      
              <div class="col p-5" style={{textAlign:'start'}}>
                <h3>Data Engineering</h3>
                <p>A data engineer designs and manages the infrastructure and systems for collecting, storing, and processing data, enabling organizations to access and analyze information effectively for decision-making and analytics. They are responsible for building and maintaining data pipelines, databases, and ETL processes.</p>
              </div>
          </div>
        </div>

        <div class='container pb-5'>
          <div class="row">
            <div class="col p-5" style={{ display:"flex"}}>
              <img src={require('../../Assets/autoopti.jpeg')} alt='Autoopti' fluid/>
            </div>
      
              <div class="col p-5" style={{textAlign:'start'}}>
                <h3>Software Development</h3>
                <p>Designs, codes, tests, and maintains software applications and systems, creating solutions to meet specific user or business needs. They collaborate with teams to ensure software functions correctly and efficiently, while also addressing any issues that arise.</p>
              </div>
          </div>
        </div>

        <div class='container pb-5'>
          <div class="row">
            <div class="col p-5" style={{ display:"flex"}}>
              <img src={require('../../Assets/cloud-visua.jpeg')} alt='Cloud-Visua' fluid/>
            </div>
      
              <div class="col p-5" style={{textAlign:'start'}}>
                <h3>Material Compliance</h3>
                <p>Material compliance, including RoHS (Restriction of Hazardous Substances) and REACH (Registration, Evaluation, Authorization, and Restriction of Chemicals), involves ensuring that products and materials meet regulatory requirements regarding the use of hazardous substances and the registration of chemicals to protect human health and the environment. This typically entails thorough testing, documentation, and reporting to demonstrate compliance with these regulations.</p>
              </div>
          </div>
        </div>

        <div class='container pb-5'>
          <div class="row">
            <div class="col p-5" style={{ display:"flex"}}>
              <img src={require('../../Assets/hardware.jpg')} alt='Cloud-Visua' style={{height:"250px"}} fluid/>
            </div>
      
              <div class="col p-5" style={{textAlign:'start'}}>
                <h3>Hardware Development</h3>
                <p>Hardware development refers to the process of designing, prototyping, and manufacturing physical electronic components or devices. It involves activities such as circuit design, PCB (Printed Circuit Board) layout, selecting and sourcing components, firmware or software development for hardware control, testing, and production scaling. Hardware development can range from simple electronic circuits to complex systems like microprocessors, sensors, and embedded devices, often used in computers, smartphones, IoT devices, and more.</p>
              </div>
          </div>
        </div>

        <div class='container pb-5'>
          <div class="row">
            <div class="col p-5" style={{ display:"flex"}}>
              <img src={require('../../Assets/cloud-visua.jpeg')} alt='Cloud-Visua' fluid/>
            </div>
      
              <div class="col p-5" style={{textAlign:'start'}}>
                <h3>Virtual Assistance</h3>
                <p>We providing remote administrative, technical, or support services to individuals or businesses, typically performed by a virtual assistant who works from a remote location.</p>
              </div>
          </div>
        </div>


    </div>
    </>
  )
}
