import CareerCard from "../../CareerCard";

function HardwareEngineer() {
    return (
      <div className="container pt-5">
        <h1>Electrical Hardware Design Engineer </h1>
        <div className="row">
          <div className="col">          
            <h4>Job Description</h4>
            <p>We are seeking a highly skilled and detail-oriented Electrical Hardware Design Engineer to join our team. The successful candidate will be responsible for designing and developing electrical hardware components and systems for our products. This role involves collaborating with cross-functional teams, conducting research, creating schematics, and ensuring that designs meet safety and performance standards.</p>
            <h1>Responsibilities</h1>
            <ul>
                <li>Develop electrical hardware designs, including schematics, circuit layouts, and PCB (Printed Circuit Board) layouts, for new products or enhancements to existing products.</li>
                <li>Collaborate with cross-functional teams, including mechanical engineers and software engineers, to ensure the integration of hardware components into the overall product design.</li>
                <li>Select and source electronic components, such as microcontrollers, sensors, connectors, and power supplies, based on project requirements and budget constraints.</li>
                <li>Create prototypes and conduct testing to evaluate the functionality and performance of electrical hardware designs.</li>
                <li>Troubleshoot and debug hardware issues, making necessary design modifications to improve performance.</li>
                <li>Ensure that electrical hardware designs comply with industry standards, safety regulations, and relevant certifications.</li>
                <li>Collaborate with regulatory bodies and certification agencies as needed.</li>
                <li>Maintain detailed documentation of hardware designs, including specifications, schematics, and bill of materials (BOM).</li>
                <li>Work within budget constraints to optimize component selection and design for cost-effectiveness.</li>
                <li>Stay up-to-date with advancements in electrical engineering technology and best practices.</li>
                <li>Continuously improve and optimize existing hardware designs.</li>
                
            </ul>
            <h4>Requirements</h4>
            <ul>
                <li>Bachelor's degree in Electrical Engineering or a related field (Master's degree may be preferred for senior roles).</li>
                <li>Proven experience in electrical hardware design and development.</li>
                <li>Proficiency in CAD software for schematic and PCB design (e.g., Altium, Eagle, KiCad).</li>
                <li>Strong knowledge of analog and digital circuit design, microcontrollers, and embedded systems.</li>
                <li>Strong knowledge of analog and digital circuit design, microcontrollers, and embedded systems.</li>
                <li>Understanding of EMC/EMI considerations and mitigation techniques.</li>
                <li>Excellent problem-solving skills and the ability to debug complex hardware issues.</li>
                <li>Knowledge of industry standards and regulations (e.g., IEEE, IEC, UL).</li>
                <li>Effective communication and teamwork skills.</li>
                <li>Strong attention to detail and a commitment to quality.</li>
            </ul>
          </div>
          <div className="col pt-5 pb-5" style={{minWidth:'350px'}}>
            <CareerCard
            title="Electrical Hardware Design Engineer"
            location="Nagpur, IN"
            description="Specializes in creating and developing electrical circuits and components for various applications, ensuring optimal performance and functionality."
            learnMoreButton={false}
            contactEmail=" hr@salvesoft.com"
            showMailButton={true}
            />
          </div>
        </div>
      </div>
    );
  }
  
  export default HardwareEngineer;