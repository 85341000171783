import React from "react";
import ContactCard from "../Contact";

function Contact() {
  return (
   <div>
    <h1 className='contact'>Feel free to contact us</h1>
    <div class="container pt-5 pb-5">
      <div class="row">
        <div class="col-sm">
          <img class="row justify-content-md-center" src={require('../../Assets/career.jpg')} alt='career' className="container-fluid" style={{paddingTop:'10%', paddingBottom:'10%'}}/>
        </div>
        <div class="col-sm">
          <ContactCard />
        </div>
      </div>
    </div>
   </div>

  );
}

export default Contact;