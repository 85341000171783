import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards pt-5'>
      <h1>Industries we cater to</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={require('../images/img-9.jpg')}
              text='Manages the infrastructure and systems for collecting, storing, and processing data, enabling organizations to access and analyze information effectively for decision-making and analytics.'
              label='Data Engineering'
              path='/services'
            />
            <CardItem
              src={require('../images/img-2.jpg')}
              text='Designs, codes, tests, and maintains software applications and systems, creating solutions to meet specific user or business needs.'
              label='Software Development'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={require('../images/img-3.jpg')}
              text='Ensuring that products and materials meet regulatory requirements regarding the use of hazardous substances and the registration of chemicals to protect human health and the environment.'
              label='Material Compliance'
              path='/services'
            />
            <CardItem
              src={require('../images/img-4.jpg')}
              text=' Designing, prototyping, and manufacturing physical electronic components or devices.'
              label='Hardware Development'
              path='/services'
            />
          </ul>
        </div>

      
        <div class="container pt-5 pb-5">
          <h2 className='pb-5 text-center'>What clients say about us !! </h2>
          <div className="row gap-4">
            <div class="col-sm">
            <div className='mt-1 mb-1'>
              <div className="card-testi"  style={{width:"100%" ,height:"28rem"}}>
                <img src={require('../Assets/Nikolay.jpg')} className="card-img-topp img-testi" alt="..." />
                <div className="card-bodyy">
                  <h5 className="card-titlee">Nikolay</h5>
                  <p>NY Global</p>
                  <p className="card-textt">“Engineering Ingenuity and with an innate understanding of their customer’s needs, the team at SalVentureTech are always a pleasure to work with.”</p>
                  {/* <a href="#" className="btnn btnn-primary">Go somewhere</a> */}
                </div>
              </div>
              </div>
            </div>
            <div class="col-sm">
            <div className='mt-1 mb-1'>
              <div className="card-testi"  style={{width:"100%" ,height:"28rem"}}>
                <img src={require('../Assets/Travis.jpg')} className="card-img-topp img-testi" alt="..." />
                <div className="card-bodyy">
                  <h5 className="card-titlee">Travis</h5>
                  <p>-</p>
                  <p className="card-textt">“SalveSoft has an amazing team of individuals and has been an excellent experience working with them”</p>
                  {/* <a href="#" className="btnn btnn-primary">Go somewhere</a> */}
                </div>
              </div>
              </div>
            </div>
            <div class="col-sm">
            <div className='mt-1 mb-1'>
              <div className="card-testi crd-body-1"  style={{width:"100%" ,height:"28rem"}}>
                <img src={require('../Assets/Drik.jpg')} className="card-img-topp img-testi" alt="..." />
                <div className="card-bodyy">
                  <h5 className="card-titlee">Dirk</h5>
                  <p>SuperTaxRefunds</p>
                  <p className="card-textt txt-p">“We are associated with this team since 2014 and it’s been great working with them. Absolute professionalism, honesty and great to work with. We would definitely recommend working with them. ”</p>
                  {/* <a href="#" className="btnn btnn-primary">Go somewhere</a> */}
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>

        <div className='pt-5'>
          <h2 className='pb-5'>“Engineering Ingenuity and with an innate understanding of their customer's needs, the team at SalveSoft are always a pleasure to work with.”</h2>
        </div>
        
      </div>
    </div>
  );
}

export default Cards;
