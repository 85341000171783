import CareerCard from "../../CareerCard";

function TeleCaller() {
    return (
      <div className="container pt-5">
        <h1>Tele Caller Executive </h1>
        <div className="row">
          <div className="col">
            <h4>Job Description</h4>
            <p>We are seeking a motivated and results-driven Telecaller to join our dynamic sales team. The successful candidate will be responsible for making outbound calls to potential customers, introducing our products or services, and persuading them to make a purchase or take specific actions. You will also handle inbound inquiries and provide excellent customer.</p>
            <h1>Responsibilities</h1>
            <ul>
              <li>Studying the details of each offering and remaining abreast of updates to these offerings.</li>
              <li>Obtaining and updating lists of individuals' contact details.</li>
              <li>Calling active and budding clients to encourage the purchase of items.</li>
              <li>Addressing clients' uncertainties, grievances, and suggestions on time.</li>
              <li>Noting important details of each conversation.</li>
              <li>Communicating verbal acceptances of offers to our sales team for closing.</li>
              <li>Recording all successful and unsuccessful attempts to close sales.</li>
              <li>Attending regular team meetings to clarify progress and performance-related expectations.</li>
              <li>Conducting each of your functions with the utmost respect, regardless of others' dispositions.</li>
            </ul>
            <h4>Requirements</h4>
            <ul>
              <li>High school diploma or equivalent.</li>
              <li>Prior experience as a telecaller or similar.</li>
              <li>Completion of a sales-related training program is preferred.</li>
              <li>Computer literate.</li>
              <li>Exposure to diverse viewpoints, cultural norms, and experiences.</li>
              <li>Superb verbal communication.</li>
              <li>Ability to switch your communication style on a whim.</li>
              <li>Superb interpersonal, research, and record-keeping skills.</li>
              <li>Capacity to receive critique without internalizing it.</li>
            </ul>
          </div>
          <div className="col pt-5 pb-5" style={{minWidth:'350px'}}>
            <CareerCard
            title="Tele Caller Executive"
            location="Nagpur, IN"
            description="Work experience as a Telecaller, TeleMarketer, or a similar role with 0-1 year experience. Exceptional oral and written communication skills."
            learnMoreButton={false}
            contactEmail=" hr@salvesoft.com"
            showMailButton={true}
            />
          </div>
          </div>
      </div>
    );
  }
  
  export default TeleCaller;