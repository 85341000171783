import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Services from './components/pages/Services';
import Contact from './components/pages/Contact';
import Career from './components/pages/Career';
import About from './components/pages/About';
import FullStackDeveloper from './components/pages/jobposts/FullStackDeveloper';
import FlutterDeveloper from './components/pages/jobposts/FlutterDeveloper';
import SrDataEngineer from './components/pages/jobposts/SrDataEngineer';
import TeleCaller from './components/pages/jobposts/TeleCaller';
import HrManager from './components/pages/jobposts/HrManager';
import SalesManager from './components/pages/jobposts/SalesManager';
import HardwareEngineer from './components/pages/jobposts/HardwareEngineer';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='*' element={<Home />} />
          <Route path='/' element={<Home />} />
          <Route path='/services' element={<Services />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/career' element={<Career />} />
          <Route path='/career/full-stack-developer' element={<FullStackDeveloper />} />
          <Route path='/career/app-developer' element={<FlutterDeveloper />} />
          <Route path='/career/sr-data-engineer' element={<SrDataEngineer />} />
          <Route path='/career/tele-caller-executive' element={<TeleCaller />} />
          <Route path='/career/hr-manager' element={<HrManager />} />
          <Route path='/career/sales-manager' element={<SalesManager />} />
          <Route path='/career/electrical-hardware-engineer' element={<HardwareEngineer />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
