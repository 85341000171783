import CareerCard from "../../CareerCard";

function FlutterDeveloper() {
    return (
      <div className="container pt-5">
        <h1>Flutter Developer </h1>
        <div className="row">
          <div className="col">
            <h4>Job Description</h4>
            <p>We are seeking a skilled Flutter Developer to join our dynamic team. As a Flutter Developer, you will be responsible for developing high-quality mobile applications using the Flutter framework. You will collaborate closely with our cross-functional team to create innovative and user-friendly mobile experiences. If you have a passion for mobile development and enjoy working in a fast-paced environment, we want to hear from you.</p>
            <h1>Responsibilities</h1>
            <ul>
              <li>Develop and maintain high-quality mobile applications using Flutter framework.</li>
              <li>Collaborate with designers, product managers, and other stakeholders to understand project requirements and translate them into functional mobile applications.</li>
              <li>Write clean, efficient, and maintainable code using best practices.</li>
              <li>Troubleshoot and debug issues in existing applications and provide timely resolutions.</li>
              <li>Optimize application performance for smooth and seamless user experience.</li>
              <li>Stay updated with the latest trends and advancements in mobile development and recommend improvements to enhance the application features and functionality.</li>
              <li>Conduct thorough testing to identify and fix bugs and ensure application stability.</li>
              <li>Work closely with the backend development team to integrate APIs and deliver end-to-end mobile solutions.</li>
              <li>Participate in code reviews to maintain code quality and ensure adherence to coding standards.</li>
            </ul>
            <h4>Requirements</h4>
            <ul>
                <li>Proven experience as a Flutter Developer or Mobile App Developer.</li>
                <li>Strong knowledge of Dart programming language and the Flutter framework.</li>
                <li>Solid understanding of the full mobile development lifecycle.</li>
                <li>Proficient in working with APIs and integrating them into mobile applications.</li>
                <li>Familiarity with version control systems such as Git.</li>
                <li>Experience with mobile app testing and debugging techniques.</li>
                <li>Ability to collaborate effectively with cross-functional teams.</li>
                <li>Excellent problem-solving and analytical skills.</li>
                <li>Strong attention to detail and commitment to delivering high-quality work.</li>
                <li>Good communication skills and the ability to effectively articulate ideas and solutions.</li>
                <li>A portfolio of previous mobile applications developed using Flutter would be an advantage.</li>
            </ul>
          </div>
          <div className="col pt-5 pb-5" style={{minWidth:'350px'}}>
            <CareerCard
            title="Flutter Developer"
            location="Nagpur, IN"
            description="As a App Developer, you will be responsible for designing, developing, and maintaining our mobile applications."
            learnMoreButton={false}
            contactEmail=" hr@salvesoft.com"
            showMailButton={true}
            />
          </div>
        </div>
      </div>
    );
  }
  
  export default FlutterDeveloper;