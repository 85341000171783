import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const form = useRef();
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Message, setMessage] = useState('');
  const sendEmail = (e) => {
    e.preventDefault();
    setName('');
    setEmail('');
    setMessage('');
    

    //replace credientials 
    emailjs.sendForm('service_y6yocn9', 'template_4y8b01u', form.current, 'XHXxsG62n7Serqhfr')
      .then((result) => {
        toast.success("Message Sent Sucessfully!");
        console.log(result.text);
        console.log("Message Sent");
          
      }, (error) => {
          toast.error("Message Was Not Sent!");
          console.log(error.text);
      });
  };
  return (
    <div>
    <div className='contact-us' style={{maxWidth:'20rem', margin: 'auto'}}>
        <h3 className='pb-4'>Let's get in touch</h3>
          <form 
            ref={form} onSubmit={sendEmail}
            style={{display: 'flex', flexDirection: 'column', gap:'3rem'}}>
            <input
              type="text"
              name="Name"
              value={Name}
              placeholder="Name"
              autoComplete="off"
              required
              onChange={(e) => setName(e.target.value)}
            />

            <input
              type="email"
              name="Email"
              value={Email}
              placeholder="Email"
              autoComplete="off"
              required
              onChange={(e) => setEmail(e.target.value)}
            />

            <textarea
              style={{maxHeight:"100px", minHeight: "30px"}}
              name="message"
              value={Message}
              cols="30"
              rows="6"
              autoComplete="off"
              placeholder="Message"
              required
              onChange={(e) => setMessage(e.target.value)}></textarea>

            <input type="submit" value="Send Message" />
          </form>
    </div>
    <ToastContainer />
    </div>
  )
};
export default Contact;